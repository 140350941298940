import { objectEntries } from '@vueuse/core'

import { type RouteLocationNormalizedLoaded } from 'vue-router'
import { DEFAULT_THEME_COLOR } from '~/lib/constants'

export const useToast = () => {
  const toasts = useState<Set<Toast>>('ui/toasts', () => new Set())
  const addToast = (toast: Partial<Toast>) => {
    const timeout = toast.timeout ?? 3 + (toast.description?.length ?? 0) / 17
    const defaultToast: Toast = {
      id: Date.now(),
      title: '',
      description: '',
      type: 'info',
      timeout
    }
    const newToast = { ...defaultToast, ...toast }
    toasts.value = toasts.value.add(newToast)
    return newToast
  }
  const removeToast = (toast: Toast) => {
    toasts.value.delete(toast)
    triggerRef(toasts)
  }
  return { toasts, addToast, removeToast }
}

export const getWindowSize = () => {
  if (!process.client) {
    return { width: 0, height: 0 }
  }
  const { innerWidth, innerHeight } = window
  return { width: innerWidth, height: innerHeight }
}

export const useWindowSize = () => {
  const windowSize = reactive(getWindowSize())
  const handleResize = () => Object.assign(windowSize, getWindowSize())
  watchEffect(() => {
    if (!process.client) {
      return
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  })
  return windowSize
}

const getElementSize = (target: HTMLElement) => ({
  width: target.clientWidth,
  height: target.clientHeight
})

export const useElementSize = () => {
  const reference = ref<HTMLElement>()
  const size = reactive({ width: 0, height: 0 })
  watchEffect(() => {
    if (!process.client || !reference.value) {
      return
    }
    if (size.height + size.width === 0) {
      Object.assign(size, getElementSize(reference.value))
    }

    const resizeObserver = new ResizeObserver((entries) =>
      requestAnimationFrame(() => {
        for (const entry of entries) {
          Object.assign(size, getElementSize(entry.target as HTMLElement))
        }
      })
    )
    resizeObserver.observe(reference.value)
    return () => reference.value && resizeObserver.unobserve(reference.value)
  })
  return { reference, size }
}

export const useRouteModal = (
  routes?: string[]
): [Ref<boolean>, RouteLocationNormalizedLoaded] => {
  const route = useRoute()
  const open = computed(
    () => !!routes?.find((r) => route.path.match(new RegExp(r)))
  )
  return [open, route]
}

export const useOnIntersect = (
  callback: () => void,
  options: IntersectionObserverInit
) => {
  const element = ref<HTMLElement>()
  watchEffect(() => {
    if (element.value) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            callback()
          }
        })
      }, options)
      observer.observe(element.value)
      return () => element.value && observer.unobserve(element.value)
    }
  })
  return element
}

export const useOpenModals = () =>
  useState<Record<string, boolean>>('openModals', () => ({}))

export const useModalThemeColor = (isOpen: Ref<boolean>) => {
  const modalId = randomUUID()

  const metaTag = document.querySelector('meta[name="theme-color"]')
  const openModals = useOpenModals()

  const open = computed(() => {
    for (const [, modalOpen] of objectEntries(openModals.value)) {
      if (modalOpen === true) return true
    }
    return false
  })

  onBeforeUnmount(() => {
    changeColor(DEFAULT_THEME_COLOR)
  })

  const changeColor = (color: string) => {
    requestAnimationFrame(() => metaTag?.setAttribute('content', color))
  }

  watch(isOpen, (value) => {
    openModals.value = { ...openModals.value, [modalId]: value }
  })

  watch(open, (value) => {
    if (value) {
      changeColor('#e2e2e2')
    } else {
      changeColor(DEFAULT_THEME_COLOR)
    }
  })
}

export const preventDefault = (event: Event) => {
  event.preventDefault()
  event.stopPropagation()
}

export const COLOR_PICKER_COLORS = [
  '#6b7280',
  '#57534e',
  '#5E72E4',
  '#dc2626',
  '#ea580c',
  '#d97706',
  '#ca8a04',
  '#65a30d',
  '#16a34a',
  '#059669',
  '#0d9488',
  '#0891b2',
  '#0284c7',
  '#2563eb',
  '#4f46e5',
  '#7c3aed',
  '#9333ea',
  '#c026d3',
  '#db2777',
  '#e11d48'
]

export const getRandomColor = (): string => {
  const randomIndex = Math.floor(Math.random() * COLOR_PICKER_COLORS.length)
  return COLOR_PICKER_COLORS[randomIndex]
}
